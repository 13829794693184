/*.stonk-input { width: 3em; }

.react-datepicker__input-container input {
    width: 6em;
}

.time-select { width: 6em; }*/

@media only screen and (max-device-width: 480px) {
    .heatmap-canvas-container {
        height: 40vh;
    }

    .distribution-canvas-container {
        height: 40vh;
    }

}
